<template>
    <div class="card b-1 pt-4 bs-4">

        <h4 class="p-4 font-inter-bold">Set A New Password</h4>

        <div class="row">

            <div class="col-12 col-md-6 order-2 order-md-1">

                <s-form class="p-4" @submit="updatePassword" ref="formValidator">

                    <validated-input :type="passwordType" label="Current Password"
                                     :rules="{required:true}" name="Current Password"
                                     v-model="model.current_password" class="c-input-1"/>

                    <validated-input :type="passwordType" label="Enter New Password"
                                     :rules="{required:true}" name="New Password"
                                     v-model="model.new_password" class="c-input-1"/>

                    <validated-input :type="passwordType" label="Confirm Password"
                                     :rules="{required:true}" name="Confirm Password"
                                     v-model="model.confirm_password" class="c-input-1"></validated-input>

                    <div class="mt-2" v-if="showErrorMessage">
                        <span class="text-danger">
                            {{ errorMessage }}
                        </span>
                    </div>

                    <validated-checkbox v-model="showPasswordType" label="Show password"/>

                    <div class="fl-x">
                        <btn text="Update" :loading="loading" loading-text="Updating..." design=""
                             class="px-4 c-bg-7"></btn>
                    </div>

                    <div class="mt-3 fl-x" v-if="showSuccess">

                        <span><i class="fa fa-check-circle fa-3x text-success"></i></span>

                        <span class="ml-lg-5 fs-lg-2">
                            Your new password has been updated. <br>
                            Please <router-link class="text-decoration-none text-primary"
                                                to="/login/">Login</router-link>
                            to continue.
                        </span>
                    </div>

                </s-form>
            </div>

            <div class="col-12 col-md-6 order-1 order-md-2">

                <div class="fl-x fl-j-c fl-a-s h-100">
                    <img v-if="currentUser.is_superuser" class="img-fluid"
                         src="../../assets/web/super-admin/update-password/update-password.png" alt="">

                    <img v-else src="../../assets/web/auth/bgi.png" class="img-fluid" alt="">
                </div>

            </div>

        </div>

    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../data/urls';
import { mapGetters } from 'vuex';

export default {
    name : 'UpdatePassword',

    data () {
        return {
            showSuccess      : false,
            passwordType     : 'password',
            showPasswordType : false,
            showErrorMessage : false,
            loading          : false,
            errorMessage     : '',
            model            : {
                current_password : '',
                new_password     : '',
                confirm_password : ''
            }
        };
    },

    computed : {
        ...mapGetters(['currentUser'])
    },

    watch : {
        showPasswordType : {
            handler () {
                this.showPassword();
            }
        }
    },

    methods : {
        showPassword () {
            if (this.showPasswordType === false) {
                this.passwordType = 'password';
            } else {
                this.passwordType = 'text';
            }
        },

        async updatePassword () {
            this.loading = true;
            const response = await axios.form(urls.auth.updatePassword, this.model);
            if (response.data.success === true) {
                this.loading = false;
                this.showSuccess = true;
                await this.$router.push('/login/').then(() => {
                    window.location.reload(true);
                });
            } else {
                this.$refs.formValidator.setErrors(response.data.errors);
                this.errorMessage = response.data.message;
                this.showErrorMessage = true;
            }
            this.loading = false;
        }
    }

};
</script>

<style scoped>

</style>
